<script>
import DeepClone from 'lodash/cloneDeep'
import _baseDropdownPicker from '../_base-dropdown-picker.vue'
import { getRootPluaralTranslator } from '@utils/get-module-translator'

const __tc = getRootPluaralTranslator()
export const OriginOptions = () => [
  { id: 'manual', key: 'manual', text: __tc('manual') },
  { id: 'discovery', key: 'discovery', text: __tc('discovery') },
  { id: 'agent', key: 'agent', text: __tc('agent') },
  { id: 'csv_imported', key: 'csv_imported', text: __tc('csv_imported') },
  { id: 'excel_imported', key: 'excel_imported', text: __tc('excel_imported') },
  { id: 'purchase', key: 'purchase', text: __tc('purchase') },
  { id: 'sccm', key: 'sccm', text: __tc('sccm') },
  { id: 'poller', key: 'poller', text: __tc('poller') },
]

export default {
  name: 'OriginPicker',
  extends: _baseDropdownPicker,
  data() {
    this.unassignedValue = null
    return {}
  },
  computed: {
    options() {
      return DeepClone(OriginOptions())
    },
  },
}
</script>
