var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isEditing)?_c('MComment',{staticClass:"work-conversation",scopedSlots:_vm._u([{key:"avatar",fn:function(){return [_c('FlotoUserAvatar',{attrs:{"size":"large","disabled":_vm.isPortalLogin,"user-id":_vm.ownerTechnician.id || _vm.owner}})]},proxy:true},{key:"content",fn:function(){return [_c('MRow',{attrs:{"gutter":0}},[_c('MCol',{staticClass:"box-container",class:_vm.isDraft ? 'border-secondary-red' : _vm.conversation.borderClass},[(_vm.isPrivate && _vm.usePrivateIndicator)?_c('MTooltip',{scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('MIcon',{staticClass:"mr-2 text-neutral absolute",style:(_vm.isRtl
                  ? { top: '15px', right: '0px' }
                  : { top: '15px', left: '4px' }),attrs:{"name":"lock-alt"}})]},proxy:true}],null,false,3080813146)},[_vm._v(" "+_vm._s(_vm.$t('not_visible_to_requester'))+" ")]):_vm._e(),(_vm.conversation.type === 'feedback')?[_c('Feedback',_vm._b({},'Feedback',{ feedback: _vm.$props },false))]:(_vm.conversation.type === 'call_log')?[_c('CallLog',_vm._b({attrs:{"conversation":_vm.conversation}},'CallLog',{ callLog: _vm.$props },false))]:[_c('MCol',{staticClass:"editor-row"},[_c('MRow',{staticClass:"flex-no-wrap items-start",attrs:{"gutter":0}},[_c('MCol',{staticClass:"mr-2"},[(_vm.isDraft)?_c('div',{staticClass:"draft-text"},[_vm._v(" "+_vm._s(("[" + (_vm.$tc('draft')) + "]"))+" ")]):_vm._e(),(
                    [
                      'forward_notification',
                      'technician_to_requester' ].indexOf(_vm.conversation.type) >= 0
                  )?[_c('div',[_c('h5',[_vm._v(" "+_vm._s(_vm.subject))]),((_vm.emailIds || []).length > 0)?_c('FlotoFormItemReadOnly',{attrs:{"label":_vm.$tc('email', 2)}},[_c('FlotoTagsPicker',{attrs:{"value":_vm.emailIds,"disabled":"","type":"email"}})],1):_vm._e(),((_vm.ccEmailIds || []).length > 0)?_c('FlotoFormItemReadOnly',{attrs:{"label":_vm.$tc('cc_email', 2)}},[_c('FlotoTagsPicker',{attrs:{"value":_vm.ccEmailIds,"disabled":"","type":"email"}})],1):_vm._e(),_c('MDivider')],1)]:_vm._e(),_c('FlotoReadMore',{attrs:{"height":_vm.richBoxRows * 20}},[_c('FlotoRichEditor',{staticClass:"flex-1 border-none mb-1",attrs:{"value":_vm.text,"disabled":true,"enable-mention":true}})],1)],2),_c('MCol',{staticClass:"flex items-center",class:_vm.conversation.textClass,attrs:{"auto-size":""}},[(!_vm.disabled && _vm.canEdit)?_c('a',{staticClass:"text-neutral",attrs:{"href":"javascript:;"},on:{"click":_vm.handleEdit}},[_c('MTooltip',{scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('MIcon',{staticClass:"mx-1 text-primary",attrs:{"name":"pencil"}})]},proxy:true}],null,false,3180898677)},[_vm._v(" "+_vm._s(_vm.$t('edit'))+" ")])],1):_vm._e(),(!_vm.disabled && _vm.canDelete)?_c('FlotoDeleteBtn',{staticClass:"mx-1",attrs:{"message":_vm.$t('confirm_remove_item', { item: _vm.toolTipText })},on:{"confirm":function($event){return _vm.$emit('delete')}}}):_vm._e(),(!_vm.disabled)?_c('MTooltip',{scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('MIcon',{staticClass:"mx-1",attrs:{"name":_vm.conversation.icon,"size":"lg"}})]},proxy:true}],null,false,2347945732)},[_vm._v(" "+_vm._s(_vm.toolTipText)+" ")]):_vm._e(),(
                    [
                      'technician_to_requester',
                      'requestor_to_technician',
                      'forward_notification',
                      'other_user_to_requster' ].indexOf(_vm.type) >= 0 &&
                    !_vm.isPortalLogin &&
                    !_vm.isDraft
                  )?_c('MTooltip',{scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('MIcon',{staticClass:"mx-1 cursor-pointer text-primary",attrs:{"name":"share","size":"lg"},on:{"click":function($event){return _vm.$emit('forward', {
                          text: _vm.text,
                          attachments: _vm.attachments,
                          createdAt: _vm.createdAt,
                          owner: _vm.owner,
                        })}}})]},proxy:true}],null,false,1326991243)},[_vm._v(" "+_vm._s(_vm.$t('forward'))+" ")]):_vm._e()],1)],1)],1)],_c('MCol',{staticClass:"mb-2"},[_c('MRow',{attrs:{"gutter":0}},[_c('MCol',{attrs:{"size":12}},[_c('FlotoAttachment',{attrs:{"disabled":""},model:{value:(_vm.attachments),callback:function ($$v) {_vm.attachments=$$v},expression:"attachments"}})],1),_c('MCol',{staticClass:"mt-2",attrs:{"size":12}},[_c('MRow',[_c('MCol',{attrs:{"auto-size":""}},[_c('div',{staticClass:"text-neutral"},[_c('small',[_vm._v(" "+_vm._s(_vm.$t('added_by'))+" "),_c('FlotoUserDrawer',_vm._b({attrs:{"user-id":_vm.owner,"disabled":_vm.isPortalLogin}},'FlotoUserDrawer',Object.assign({}, (_vm.type === 'requestor_to_technician'
                            ? { userId: _vm.owner }
                            : {
                                user: _vm.ownerTechnician,
                              })),false))],1)])]),_c('MCol',{style:(_vm.isRtl ? { marginRight: 'auto' } : { marginLeft: 'auto' }),attrs:{"auto-size":""}},[_c('div',{staticClass:"text-neutral"},[_c('small',[_vm._v(" "+_vm._s(_vm._f("datetime")(_vm.createdAt))+" ("+_vm._s(_vm._f("timeago")(_vm.createdAt))+") ")])])])],1)],1)],1)],1)],2)],1)]},proxy:true}],null,false,3384793972)}):_c('AddBox',_vm._b({attrs:{"id":_vm.id,"processing":_vm.processing,"conversation-type":_vm.conversation,"resource":_vm.resource,"allow-save-in-draft":_vm.conversation.type !== 'note' && _vm.allowSaveInDraft,"is-private":_vm.isPrivate && _vm.usePrivateIndicator,"allow-templates":_vm.allowTemplates,"allow-subject":_vm.allowSubject,"allow-email":_vm.allowEmail,"allow-cc-email":_vm.allowCcEmail,"module-name":_vm.moduleName,"allow-knowledges":_vm.allowKnowledges,"form-data":{ subject: _vm.subject, emailIds: _vm.emailIds, ccEmailIds: _vm.ccEmailIds }},on:{"submit":_vm.updateConversation,"remove":_vm.resetConversation}},'AddBox',_vm.formData,false))}
var staticRenderFns = []

export { render, staticRenderFns }