<template>
  <MRow class="flex-grow" :gutter="0">
    <MCol class="preview-holder" :size="wrapperSize">
      <div v-if="formFields.length === 0 && showNoData">
        <slot name="no-fields">
          <FlotoNoData />
        </slot>
      </div>
      <FlotoContentLoader v-else :loading="loading">
        <component
          :is="withSubmit ? 'FlotoForm' : 'div'"
          id="ticket-form"
          ref="formRef"
          :key="renderCount"
          class="flex flex-wrap flex-col"
          :class="{ 'read-only-form': disabled }"
          @submit="handleSubmit"
        >
          <MRow :gutter="0" class="flex-1 w-full">
            <template v-for="element in formFields">
              <Field
                :id="element.guid"
                :key="element.guid"
                :focus-event-brodcast="focusEventBrodcast"
                :disabled="disabled"
                :value="
                  formValue[
                    element.isSystemField ? element.paramName : element.id
                  ]
                "
                :group-id="formValue.groupId"
                :field="element"
                :module-name="moduleName"
                :validate-archived-value="validateArchivedValue"
                :api-field-update-fn="apiFieldUpdateFn"
                :apply-form-rules="applyFormRules"
                :field-rules-state="fieldRulesState"
                :form-value="formValue"
                :dynamic-field-options="dynamicFieldOptionsMap[element.id]"
                v-bind="{
                  ...(element.type !== 'section'
                    ? { 'data-guid': element.guid }
                    : {}),
                }"
                @templateSelected="handleTemplateSelected"
                @requesterSelected="handleRequesterSelected"
                @requesterIdSelected="handleRequesterIdSelected"
                @update-value="
                  ($event, isDelay) =>
                    handleUpdate(
                      {
                        [element.isSystemField
                          ? element.paramName
                          : element.id]:
                          element.type === 'richtextarea'
                            ? $event || ''
                            : $event,
                      },
                      isDelay,
                      element.isSystemField ? element.paramName : element.id
                    )
                "
                @field-blur="
                  handleFieldBlur({
                    [element.isSystemField ? element.paramName : element.id]:
                      '',
                  })
                "
                @set-default-value="
                  ($event) =>
                    handleSetFieldDefaultValue(element.paramName, $event)
                "
                v-on="listeners"
              >
                <div
                  v-if="element.type === 'section'"
                  class="w-full flex flex-row flex-wrap h-full content-start relative"
                >
                  <template v-for="sectionField in element.fields">
                    <Field
                      :id="sectionField.guid"
                      :key="sectionField.guid"
                      :data-guid="sectionField.guid"
                      :focus-event-brodcast="focusEventBrodcast"
                      :module-name="moduleName"
                      :disabled="disabled"
                      :value="
                        formValue[
                          sectionField.isSystemField
                            ? sectionField.paramName
                            : sectionField.id
                        ]
                      "
                      :field="sectionField"
                      :validate-archived-value="validateArchivedValue"
                      :api-field-update-fn="apiFieldUpdateFn"
                      :apply-form-rules="applyFormRules"
                      :field-rules-state="fieldRulesState"
                      :dynamic-field-options="
                        dynamicFieldOptionsMap[sectionField.id]
                      "
                      @update-value="
                        ($event, isDelay) =>
                          handleUpdate(
                            {
                              [sectionField.isSystemField
                                ? sectionField.paramName
                                : sectionField.id]:
                                sectionField.type === 'richtextarea'
                                  ? $event || ''
                                  : $event,
                            },
                            isDelay,
                            sectionField.isSystemField
                              ? sectionField.paramName
                              : sectionField.id
                          )
                      "
                      @field-blur="
                        handleFieldBlur({
                          [sectionField.isSystemField
                            ? sectionField.paramName
                            : sectionField.id]: '',
                        })
                      "
                      @set-default-value="
                        ($event) =>
                          handleSetFieldDefaultValue(
                            sectionField.paramName,
                            $event
                          )
                      "
                      v-on="listeners"
                    />
                  </template>
                </div>
              </Field>
            </template>
            <!-- additional fields slot here only for withsubmit is false  -->
            <slot
              v-if="!withSubmit"
              name="additional-fields"
              v-bind="formValue"
            ></slot>
          </MRow>
          <template v-if="withSubmit" v-slot:submit="submitSlotData">
            <div :class="isRtl ? 'text-left' : 'text-right'">
              <slot
                v-if="!disabled"
                v-bind="submitSlotData"
                name="actions"
                :submit="handleSubmit"
              >
                <slot name="submit" :reset="submitSlotData.submit">
                  <MButton id="create-btn" type="submit">
                    <slot name="submit-text">{{ $t('create') }}</slot>
                  </MButton>
                </slot>
                <slot name="reset" :reset="submitSlotData.reset">
                  <MButton
                    id="cancel-btn"
                    variant="default"
                    class="ml-4"
                    @click="submitSlotData.reset"
                  >
                    {{ $t('cancel') }}
                  </MButton>
                </slot>
              </slot>
            </div>
          </template>
        </component>
        <!-- additional fields slot here only for withsubmit is true  -->
        <slot
          v-if="withSubmit"
          name="additional-fields"
          v-bind="formValue"
        ></slot>
      </FlotoContentLoader>
    </MCol>
  </MRow>
</template>

<script>
import Pick from 'lodash/pick'
import Moment from 'moment'
import Bus from '@utils/emitter'
import Omit from 'lodash/omit'
import api from '@api'
import { authComputed } from '@state/modules/auth'
import { transformUserForVuex } from '@data/user'
import {
  flattenFields,
  // mandatorySystemFields
} from '@data/form'
import Field from '@modules/form/components/fields/field'
import { FieldMappingComputed } from '@state/modules/field-mapping'
import { transformTicketForServer } from '@data/ticket'
import { getRequestDataFromAPIFieldApi } from '@modules/ticket/ticket-api'
import { successToast, errorToast } from '@motadata/ui'
// import {
//   executeQualification,
//   performActions,
// } from '@data/qualification-execution'
import { StatusComputed } from '@state/modules/status'
import { PriorityComputed } from '@state/modules/priority'
import { ImpactComputed } from '@state/modules/impact'
import { UrgencyComputed } from '@state/modules/urgency'
import {
  executionTypeMap,
  userTypeMap,
  eventTypeMap,
  mandatorySystemFieldParamNames,
} from '@data/form-rules'
import { getGlobalUserApi } from '@modules/users/users-api'
import {
  fieldValueParamName,
  ReferencedSystemFieldValue,
} from '@data/qualification-execution'

export default {
  name: 'FormConsumer',
  components: { Field },
  // inject: ['formRulesContext'],
  inject: {
    formRulesContext: {
      default: () => ({
        isFormRuleQualified: () => {},
      }),
    },
  },
  model: {
    event: 'change',
  },
  props: {
    allowFieldMapping: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    formFields: { type: Array, required: true },
    // eslint-disable-next-line
    withSubmit: { type: Boolean, default: true },
    wrapperSize: { type: [Number, Object], default: 12 },
    avoidDefaultValue: { type: Boolean, default: false },
    moduleName: {
      type: String,
      default() {
        return this.$constants.REQUEST
      },
    },
    showNoData: { type: Boolean, default: false },
    value: {
      type: Object,
      default() {
        return {}
      },
    },
    validateArchivedValue: { type: Boolean, default: false },
    applyFormRules: { type: Boolean, default: false },
    // eslint-disable-next-line
    focusEventBrodcast: { type: Boolean, default: false },
  },
  data() {
    return {
      loading: this.applyFormRules,
      formData: Object.assign({}, this.value),
      renderCount: 1,
      // fieldRulesState: {},
      dynamicFieldOptionsMap: {},
      requesterDetails: {},
      updatedMandatorySystemFields: [],
      openPopover: undefined,
    }
  },
  computed: {
    ...authComputed,
    ...FieldMappingComputed,
    ...StatusComputed,
    ...PriorityComputed,
    ...ImpactComputed,
    ...UrgencyComputed,
    formValue: {
      get() {
        if (this.withSubmit) {
          return this.formData
        }
        return this.value
      },
      set(value) {
        if (this.withSubmit) {
          this.formData = value
        } else {
          this.$emit('change', value)
        }
      },
    },
    listeners() {
      return Omit(this.$listeners, [
        'update-value',
        'templateSelected',
        'requesterSelected',
        'change',
      ])
    },
    fieldRulesState() {
      return (this.formRulesContext || {}).fieldRulesState || {}
    },
    formRulesUserTypeFilter() {
      if (!this.loggedIn) {
        return [userTypeMap['all'], userTypeMap['requester']]
      }
      if (this.isPortalLogin) {
        return [
          userTypeMap['all'],
          userTypeMap['requester'],
          userTypeMap['logged_in_user'],
        ]
      }
      return [
        userTypeMap['all'],
        userTypeMap['technician'],
        userTypeMap['logged_in_user'],
      ]
    },
  },
  watch: {
    formFields() {
      if (this.avoidDefaultValue === false) {
        this.$nextTick(() => this.buildDefaultData())
      }
    },
  },
  created() {
    if (this.avoidDefaultValue === false) {
      this.buildDefaultData()
    }
    if (this.avoidDefaultValue === true) {
      this.loading = false
      setTimeout(() => {
        this.setReqeusterDetails()
      })
    }
    if (this.focusEventBrodcast) {
      const onOpenPopover = (id) => {
        Bus.$emit('app:popover:broadcast:open', id)
      }
      Bus.$on('app:single:dropdown:open', onOpenPopover)

      const onClosePopover = (id) => {
        Bus.$emit('app:popover:broadcast:close', id)
      }
      Bus.$on('app:single:dropdown:close', onClosePopover)

      this.$once('hook:beforeDestroy', () => {
        Bus.$off('app:single:dropdown:close', onClosePopover)
        Bus.$off('app:single:dropdown:open', onOpenPopover)
      })
      // this.prepairDefaultRulesState()
      this.bindEvents()
    }
  },
  destroy() {
    if (this.focusEventBrodcast) {
      this.unbindEvents()
    }
  },
  mounted() {
    if (this.focusEventBrodcast) {
      const formElements = document.querySelector(
        '#ticket-form div[data-guid] :nth-child(2)'
      )
      if (formElements) {
        const id = formElements.getAttribute('data-guid')
        const inputField = formElements.querySelector('input')
        if (inputField !== null && inputField.getAttribute('id') === id) {
          inputField.focus()
        }
        Bus.$emit('app:single:dropdown:open', id)
      }
    }
  },
  methods: {
    bindEvents() {
      document.addEventListener('focusin', this.focusHandler)
    },
    unbindEvents() {
      document.removeEventListener('focusin', this.focusHandler)
    },
    focusHandler(e) {
      const targetElement = e.target
      const fieldElement = targetElement.closest('div[data-guid]')
      if (fieldElement) {
        const id = fieldElement.getAttribute('data-guid')
        Bus.$emit('app:popover:broadcast:open', id)
      }
    },
    setReqeusterDetails() {
      if (
        this.isPortalLogin &&
        !this.formValue.requesterId &&
        this.formValue.requester &&
        !Object.keys(this.requesterDetails).length
      ) {
        if (this.user.name === this.requester) {
          this.requesterDetails = this.user
          this.$emit('requester-details', this.user)
          this.handleFormLoad()
          return
        }
        api
          .get(`/requester/byemail/${this.formValue.requester}`, {
            notify: false,
          })
          .then((data) => {
            this.requesterDetails = data
            this.$emit('requester-details', data)
            this.handleFormLoad()
          })
      } else if (
        this.formValue.requesterId &&
        !Object.keys(this.requesterDetails).length
      ) {
        getGlobalUserApi(this.formValue.requesterId, { archived: true }).then(
          (data) => {
            this.requesterDetails = data
            this.$emit('requester-details', data)
            this.handleFormLoad()
          }
        )
      } else {
        this.handleFormLoad()
      }
    },
    buildDefaultData() {
      const defaultData = {}
      flattenFields(this.formFields).forEach((f) => {
        if (f.defaultValue !== undefined) {
          if (
            f.inputType === 'datetime' &&
            f.defaultValue === this.$constants.CURRENT_DATE_VALUE
          ) {
            defaultData[f.id] = Moment().valueOf()
          } else {
            defaultData[f.isSystemField ? f.paramName : f.id] = f.defaultValue
          }
        }
        if (f.isSystemField && f.inputType === 'known_error') {
          defaultData[f.paramName] = 'false'
        }
      })
      this.formValue = defaultData
      this.loading = false
      setTimeout(() => {
        this.setReqeusterDetails()
      })
    },
    handleTemplateSelected(template) {
      if (template) {
        this.$nextTick(() => {
          const visibleFormFields = flattenFields(this.formFields).map((f) =>
            f.isSystemField ? f.paramName : f.id
          )
          const templateData = {
            ...template,
            ...(template.fieldValueDetails || {}),
          }
          this.formValue = {
            ...this.formValue,
            templateId: template.id,
            ...Pick(templateData, visibleFormFields),
            knownError: String(template.knownError),
          }
          this.renderCount = this.renderCount + 1
          this.$emit('templateSelected', this.formValue)
        })
      } else {
        this.$nextTick(() => {
          if (this.avoidDefaultValue === false) {
            this.buildDefaultData()
          } else {
            this.formValue = {}
          }
          this.renderCount = this.renderCount + 1
          this.$emit('templateSelected', {})
        })
      }
    },
    handleRequesterIdSelected(data) {
      this.requesterDetails = data
    },
    handleRequesterSelected(data = {}, formLoadEvent) {
      this.requesterDetails = data
      if (Object.keys(data).length) {
        this.formValue = {
          ...this.formValue,
          requesterId: data.id,
          requester: data.name,
          requesterData: transformUserForVuex(
            {
              id: data.requesterId,
              name: data.name,
              email: data.email,
            },
            true
          ),
        }
      } else {
        this.formValue = {
          ...this.formValue,
          requesterId: undefined,
          requesterData: transformUserForVuex(
            {
              id: undefined,
              name: this.formValue.requester,
              email: undefined,
            },
            true
          ),
        }
      }
      this.$nextTick(() => {
        if (formLoadEvent) {
          this.handleFormLoad()
        }
      })
      if (!this.allowFieldMapping) {
        return
      }
      const requester = { ...data, managerId: data.managerName }
      const mapping = (this.requestFieldMapping || {}).mapping || []
      if (this.moduleName === this.$constants.REQUEST && mapping.length > 0) {
        const systemFieldValue = {}
        const customFieldValue = {}
        mapping.forEach((item) => {
          const userValue = /^\d+$/.test(item.key)
            ? (requester.fieldValueDetails || {})[+item.key]
            : (requester || {})[item.key]
          if (/^\d+$/.test(item.value)) {
            customFieldValue[+item.value] = userValue
          } else {
            systemFieldValue[item.value] = userValue
          }
        })

        this.$nextTick(() => {
          this.formValue = {
            ...this.formValue,
            ...systemFieldValue,
            fieldValueDetails: {
              ...(this.formValue.fieldValueDetails || {}),
              ...customFieldValue,
            },
            // spred custom fields
            ...customFieldValue,
            ...(Object.keys(data).length
              ? {
                  requesterId: data.id,
                  requester: data.name,
                  requesterData: transformUserForVuex(
                    {
                      id: data.id,
                      name: data.name,
                      email: data.email,
                    },
                    true
                  ),
                }
              : {
                  requesterId: undefined,
                  requesterData: transformUserForVuex(
                    {
                      id: undefined,
                      name: this.formValue.requester,
                      email: undefined,
                    },
                    true
                  ),
                }),
          }
          this.$nextTick(() => {
            if (!requester && this.avoidDefaultValue === false) {
              this.buildDefaultData()
            }
          })
        })
      }
    },
    apiFieldUpdateFn(field) {
      let requestData = transformTicketForServer(this.formValue)
      if (this.$attrs['external-data']) {
        requestData = {
          ...requestData,
          ...(this.$attrs['external-data'] || {}),
        }
      }
      return getRequestDataFromAPIFieldApi(requestData, field.id)
        .then((data) => {
          const availableFields = flattenFields(this.formFields)
          const availableFieldIds = [
            ...(availableFields.map((f) => f.id) || []),
            'requester',
          ]
          Object.keys(data).forEach((paramName) => {
            if (/^\d+$/.test(paramName)) {
              data[paramName] =
                availableFieldIds.indexOf(+paramName) >= 0
                  ? data[paramName]
                  : undefined
            }
          })
          const dynamicFieldIds = availableFields
            .filter((f) => f.type === 'dynamic')
            .map((f) => f.id)
          const dynamicFieldOptionsMap = {}
          dynamicFieldIds.forEach((fieldId) => {
            if (data[fieldId]) {
              dynamicFieldOptionsMap[fieldId] = data[fieldId]
              data[fieldId] = undefined
            }
          })
          this.formValue = {
            ...this.formValue,
            ...data,
          }
          this.dynamicFieldOptionsMap = dynamicFieldOptionsMap
          if (data.apiSuccess) {
            successToast(
              data.apiResponseMessage
                ? data.apiResponseMessage
                : this.$tc('operation_completed')
            )
          } else {
            errorToast(
              data.apiResponseMessage
                ? data.apiResponseMessage
                : this.$tc('operation_fail')
            )
          }
          this.renderCount = this.renderCount + 1
        })
        .catch(() => {
          errorToast(this.$tc('operation_fail'))
        })
    },
    handleSetFieldDefaultValue(paramName, value) {
      if (
        this.fieldRulesState[paramName] &&
        this.fieldRulesState[paramName].isDirty
      ) {
        return
      }
      this.updatedMandatorySystemFields = [
        ...this.updatedMandatorySystemFields,
        paramName,
      ]
      const fields = this.formFields.filter(
        (f) => mandatorySystemFieldParamNames.indexOf(f.paramName) >= 0
      )
      if (fields.length === this.updatedMandatorySystemFields.length) {
        setTimeout(() => {
          this.setReqeusterDetails()
        }, 100)
      }
    },
    handleFormLoad() {
      if (!this.applyFormRules) {
        return
      }
      if (
        this.moduleName === this.$constants.REQUEST &&
        !Object.keys(this.formValue).length
      ) {
        return
      }
      this.formRulesContext.executeFormRules(this.formValue, {
        executionTypes: [
          executionTypeMap['create_and_edit'],
          ...(this.formValue.id
            ? [executionTypeMap['edit']]
            : [executionTypeMap['create']]),
        ],
        userTypes: this.formRulesUserTypeFilter,
        eventType: eventTypeMap['load'],
        requesterDetails: this.requesterDetails,
      })
      this.formValue = this.formRulesContext.updatedResource
      this.loading = false
    },
    handleFieldBlur(change) {
      if (!this.applyFormRules) {
        return
      }
      this.formRulesContext.executeFormRules(this.formValue, {
        executionTypes: [
          executionTypeMap['create_and_edit'],
          ...(this.formValue.id
            ? [executionTypeMap['edit']]
            : [executionTypeMap['create']]),
        ],
        userTypes: this.formRulesUserTypeFilter,
        eventType: eventTypeMap['change'],
        triggerFields: Object.keys(change),
        requesterDetails: this.requesterDetails,
      })
      this.formValue = this.formRulesContext.updatedResource
      this.loading = false
    },
    // handleFieldBlur() {
    //   // check form rule applicability for mudule
    //   if (!this.applyFormRules) {
    //     return
    //   }
    //   if ((this.formRules || []).length) {
    //     const executedResultList = []
    //     this.formRules.forEach((rule) => {
    //       const qualResult = executeQualification(
    //         rule.qualifications,
    //         this.formValue
    //       )
    //       executedResultList.push({
    //         isQualified: qualResult,
    //         reversibleAction: rule.reversibleAction,
    //         actions: rule.actions,
    //       })
    //     })
    //     if ((executedResultList || []).length) {
    //       const result = performActions(
    //         executedResultList,
    //         flattenFields(this.formFields),
    //         this.fieldRulesState
    //       )
    //       this.fieldRulesState = result
    //       const hiddenFieldIds = []
    //       // find hidden field
    //       Object.keys(result).forEach((f) => {
    //         if (result[f].hide === true) {
    //           hiddenFieldIds.push(+f)
    //         }
    //       })
    //       if (hiddenFieldIds.length) {
    //         // set default value for hidden field before hide
    //         const defaultData = {}
    //         const fields = flattenFields(this.formFields).filter(
    //           (f) => hiddenFieldIds.indexOf(f.id) >= 0
    //         )
    //         flattenFields(fields).forEach((f) => {
    //           if (f.required) {
    //             return
    //           }
    //           // for system field find default value from computed value
    //           // mandatorySystemFields is a map of field, we have to set defalut value on hide
    //           if (
    //             f.isSystemField &&
    //             mandatorySystemFields.indexOf(f.inputType) >= 0
    //           ) {
    //             defaultData[f.paramName] = (
    //               (this[`${f.inputType}Options`] || []).find(
    //                 (v) => v.default
    //               ) || {}
    //             ).key
    //           } else {
    //             defaultData[f.isSystemField ? f.paramName : f.id] =
    //               f.defaultValue
    //           }
    //         })
    //         // set update form data with default data for hidden field
    //         this.formValue = {
    //           ...this.formValue,
    //           ...defaultData,
    //         }
    //       }
    //     }
    //   }
    //   this.loading = false
    // },
    // prepairDefaultRulesState() {
    //   const defaultState = {
    //     hide: false,
    //     mandatory: false,
    //     disable: false,
    //     isDirty: false,
    //   }
    //   const fieldRulesState = {}
    //   flattenFields(this.formFields).forEach((f) => {
    //     fieldRulesState[f.id] = defaultState
    //   })
    //   this.fieldRulesState = fieldRulesState
    // },
    submit() {
      if (this.withSubmit) {
        this.$refs.formRef.submit()
      }
    },
    handleSubmit() {
      if (
        this.applyFormRules &&
        this.formRulesContext.isFormRuleQualified({
          executionTypes: [
            executionTypeMap['create_and_edit'],
            ...(this.formValue.id
              ? [executionTypeMap['edit']]
              : [executionTypeMap['create']]),
          ],
          userTypes: this.formRulesUserTypeFilter,
          eventType: eventTypeMap['submit'],
          requesterDetails: this.requesterDetails,
        })
      ) {
        this.formRulesContext.executeFormRules(this.formValue, {
          executionTypes: [
            executionTypeMap['create_and_edit'],
            ...(this.formValue.id
              ? [executionTypeMap['edit']]
              : [executionTypeMap['create']]),
          ],
          userTypes: this.formRulesUserTypeFilter,
          eventType: eventTypeMap['submit'],
          requesterDetails: this.requesterDetails,
        })
        const formData = this.formRulesContext.updatedResource
        const fieldRulesState = this.formRulesContext.fieldRulesState
        const overridedFieldValue = {}
        Object.keys(fieldRulesState).map((key) => {
          const fieldKey = /^\d+$/.test(key) ? +key : key
          if (
            fieldRulesState[fieldKey] &&
            'setValue' in fieldRulesState[fieldKey]
          ) {
            let finalValue = fieldRulesState[fieldKey].setValue
            // check for system field
            if (/^\d+$/.test(key) === false) {
              if (
                typeof fieldRulesState[fieldKey].setValue === 'string' &&
                /^\d+$/.test(fieldRulesState[fieldKey].setValue)
              ) {
                if (
                  Object.keys(formData).indexOf(
                    fieldRulesState[fieldKey].setValue
                  ) >= 0
                ) {
                  finalValue = formData[fieldRulesState[fieldKey].setValue]
                } else {
                  finalValue = 0
                }
              } else {
                finalValue = fieldRulesState[fieldKey].setValue
              }
            }
            overridedFieldValue[fieldKey] =
              fieldValueParamName.indexOf(fieldRulesState[fieldKey].setValue) >=
              0
                ? ReferencedSystemFieldValue(
                    fieldRulesState[fieldKey].setValue,
                    formData,
                    this.requesterDetails
                  )
                : finalValue
          }
          if (
            fieldRulesState[fieldKey] &&
            'clearValue' in fieldRulesState[fieldKey]
          ) {
            overridedFieldValue[fieldKey] = fieldRulesState[fieldKey].clearValue
          }
        })
        this.$emit('submit', { ...formData, ...overridedFieldValue })
      } else {
        this.$emit('submit', this.formValue)
      }
    },
    handleUpdate(value, isDelay, paramName) {
      if (this.disabled && this.applyFormRules) {
        return
      }
      // isDelay arg for form rules setValue emitter
      if (isDelay) {
        setTimeout(() => {
          this.formValue = {
            ...this.formValue,
            ...(fieldValueParamName.indexOf(value[paramName]) >= 0
              ? {
                  [paramName]: ReferencedSystemFieldValue(
                    value[paramName],
                    this.formData,
                    this.requesterDetails
                  ),
                }
              : value),
          }
          Bus.$emit('app:form:richtextarea:rerender', paramName)
        }, 200)
      } else {
        // for regular update
        this.formValue = {
          ...this.formValue,
          ...value,
        }
      }
      // emit specific field value if required in form
      if ('startDate' in value) {
        this.$emit('startDate-update', value)
      }
      if ('endDate' in value) {
        this.$emit('endDate-update', value)
      }
    },
  },
}
</script>

<style lang="less" scoped>
.actions {
  @apply flex py-4 items-center justify-end;

  border-top: 1px solid var(--border-color);
}
</style>
