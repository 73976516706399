<template>
  <MRow class="items-start">
    <MCol class="ml-2 py-2">
      <FlotoReadMore>
        <FlotoRichEditor
          :value="feedback.text"
          :disabled="true"
          class="flex-1 border-none focusable mb-1"
        />
        <FormConsumer
          v-if="fields.length"
          :value="feedback.fieldValueDetails"
          disabled
          :form-fields="fields"
          avoid-default-value
        />
      </FlotoReadMore>
    </MCol>
    <MTooltip v-if="feedback.rating">
      <template v-slot:trigger>
        <MCol auto-size>
          <MRate v-model="feedback.rating" allow-half disabled />
        </MCol>
      </template>
      {{ tenantPrefrences.feedbackRatingValues.value[feedback.rating - 1] }}
    </MTooltip>
  </MRow>
</template>

<script>
import { FormComputed } from '@state/modules/form'
import FormConsumer from '@components/form-consumer.vue'
import { PreferenceComputed } from '@state/modules/preference'

export default {
  name: 'Feedback',
  components: { FormConsumer },
  props: { feedback: { type: Object, required: true } },
  data() {
    return {}
  },
  computed: {
    ...FormComputed,
    ...PreferenceComputed,
    fields() {
      return this.feedbackFields().filter(
        (f) =>
          this.feedback.fieldValueDetails[f.id] ||
          this.feedback.fieldValueDetails[f.id] === false
      )
    },
  },
}
</script>
