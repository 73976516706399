<template>
  <FlotoScrollDropdown
    ref="dropdownRef"
    avoid-keyboard-navigation
    :disabled="disabled"
    v-bind="attrs"
    v-on="listeners"
    @show="handleShow"
  >
    <template v-slot:trigger="triggerSlotData">
      <MultipleTrigger
        v-if="multiple"
        :toggle="triggerSlotData.toggle"
        :allow-clear="allowClear"
        :as-input="asInput"
        :text-only="textOnly"
        :focus-event-brodcast="false"
        :selected-items="selectedItemFormMultiple"
        :disabled="disabled"
        :as-tag="asTag"
        v-bind="attrs"
        :placeholder="$t('select')"
        :options="options"
        @change="handleChange"
      />
      <DropdownTrigger
        v-else
        :toggle="triggerSlotData.toggle"
        :as-input="asInput"
        :text-only="textOnly"
        :allow-clear="allowClear"
        :disabled="disabled"
        :selected-item="selectedItem"
        :placeholder="$t('select')"
        @reset="handleChange(undefined)"
      />
    </template>
    <div class="p-2 pb-0">
      <FlotoFormItem
        ref="searchBox"
        v-model="searchTerm"
        class="search-box"
        :auto-focus="false"
        :placeholder="$t('search')"
      >
        <template v-slot:prefix>
          <MIcon name="search" />
        </template>
      </FlotoFormItem>
    </div>
    <FlotoScrollView>
      <div v-for="(group, key) in groupedOptions" :key="key">
        <MMenu :class="menuClass">
          <MMenuItem
            :class="{ 'cursor-default': true, [menuItemSelectedClass]: true }"
          >
            <h6 class="py-0 my-0">{{ $tc(key) }}</h6>
          </MMenuItem>
          <MMenuItem
            v-for="(item, index) in group"
            :key="item.key"
            :class="{
              'scroll-dropdown-menu-item': true,
              [menuItemClass]: true,
              [menuItemDisabledClass]: item.disabled,
              [menuItemSelectedClass]: Array.isArray(value)
                ? value.indexOf(item.key) >= 0
                : value === item.key,
              'value-active-item': Array.isArray(value)
                ? value.indexOf(item.key) >= 0
                : value === item.key,
            }"
            @click="selectItem(item)"
          >
            {{ item.text }}
          </MMenuItem>
        </MMenu>
      </div>
    </FlotoScrollView>
  </FlotoScrollDropdown>
</template>

<script>
import DropdownTrigger from '@components/dropdown-trigger'
import MultipleTrigger from '@components/tree-picker/multiple-trigger'
import GroupBy from 'lodash/groupBy'
import { searchList } from '@utils/arr'
import { getServiceCatalogsApi } from '@modules/service-catalog/service-catalog-api'
import { CategoryComputed } from '@state/modules/category'

export default {
  name: 'ServiceCatalogPicker',
  components: { DropdownTrigger, MultipleTrigger },
  model: {
    event: 'change',
  },
  props: {
    // eslint-disable-next-line
    disabled: { type: Boolean, default: false },
    status: { type: String, default: undefined },
    value: {
      type: [String, Number, Array, Object, Boolean],
      default: undefined,
    },
    multiple: { type: Boolean, default: false },
    // eslint-disable-next-line
    allowClear: { type: Boolean, default: true },
    asInput: { type: Boolean, default: false },
    textOnly: { type: Boolean, default: false },
    asTag: { type: Boolean, default: false },
  },
  data() {
    this.menuClass = 'ant-dropdown-menu'
    this.menuItemClass = 'ant-dropdown-menu-item'
    this.menuItemDisabledClass = 'ant-dropdown-menu-item-disabled'
    this.menuItemSelectedClass = 'ant-dropdown-menu-item-selected'
    this.unassignedValue = 0
    return {
      searchTerm: '',
      loadedServiceCatalog: [],
      selectedEventName: 'serviceCatalogSelected',
    }
  },
  computed: {
    ...CategoryComputed,
    options() {
      return this.loadedServiceCatalog
    },
    groupedOptions() {
      let col = this.options
      if (this.searchTerm) {
        col = searchList(this.options, this.searchTerm, 'text')
      }
      return GroupBy(col, 'categoryName')
    },
    selectedItem() {
      if (this.options.length) {
        if (this.multiple) {
          return (this.value || []).map((id) =>
            this.options.find((o) => {
              const optionKey = o.key
              return optionKey === id
            })
          )
        }
        return (this.options || []).find((p) => {
          const optionKey = p.key
          return optionKey === this.value || optionKey === parseInt(this.value)
        })
      }
      return undefined
    },
    selectedItemFormMultiple() {
      if (this.multiple && (this.selectedItem || []).length) {
        return this.selectedItem.filter((e) => e).map((i) => i.key)
      }
      return []
    },
    listeners() {
      const { change, hide, show, click, ...listeners } = this.$listeners
      return listeners
    },
    attrs() {
      const { options, ...attrs } = this.$attrs
      return attrs
    },
  },
  created() {
    const filter = {}
    if (this.status) {
      filter.status = this.status
    }
    getServiceCatalogsApi(filter).then(({ items }) => {
      const catagoryMap = {}
      this.serviceCatalogCategories.forEach((c) => {
        catagoryMap[c.id] = c.name
      })
      return (this.loadedServiceCatalog = items.map((t) => ({
        ...t,
        categoryName: catagoryMap[t.categoryId],
        text: t.name,
        key: t.id,
      })))
    })
  },
  methods: {
    handleShow() {
      setTimeout(() => {
        this.$refs.searchBox && this.$refs.searchBox.focus()
      }, 200)
    },
    selectItem(item) {
      const value = this.multiple ? this.value || [] : this.value
      if (Array.isArray(value)) {
        if (value.indexOf(item.key) === -1) {
          this.handleChange([...value, item.key])
        } else {
          this.handleChange(value.filter((i) => i !== item.key))
        }
      } else {
        this.handleChange(item.key)
        this.hide()
      }
    },
    handleChange(value) {
      if (this.multiple) {
        this.$emit(
          'selected',
          value.map((id) => this.options.find((o) => o.key === id))
        )
        // If template picker and needs custom event for template selection
        if (this.selectedEventName) {
          this.$emit(
            this.selectedEventName,
            value.map((id) => this.options.find((o) => o.key === id))
          )
        }
        this.$emit('change', value)
        this.$emit('blur')
      } else {
        this.$emit(
          'selected',
          this.options.find((o) => o.key === value)
        )
        if (this.selectedEventName) {
          this.$emit(
            this.selectedEventName,
            this.options.find((o) => o.key === value)
          )
        }
        this.$emit('change', value || this.unassignedValue)
      }
      this.$refs.dropdownRef.hide()
    },
    hide() {
      this.$refs.dropdownRef.hide()
    },
  },
}
</script>
