<template>
  <div
    class="flex flex-1 dropdown-trigger items-center mr-4"
    :class="{
      'secondary-trigger': type === 'secondary',
    }"
  >
    <div v-if="iconName" class="icon-container">
      <CustomIcon v-if="customIcon" :name="iconName" />
      <MIcon
        v-else
        :name="iconName"
        :size="type === 'secondary' ? 'lg' : '2x'"
      />
    </div>
    <div class="details flex flex-1 flex-col text-ellipsis mx-1">
      <div v-if="lable" class="lable-text text-ellipsis">
        <slot name="lable">{{ lable }}</slot>
      </div>
      <div class="value-text text-ellipsis">
        <slot name="value">
          {{ value }}
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import CustomIcon from '@components/custom-icon/custom-icon'
export default {
  name: 'FlotoTriggerView',
  components: { CustomIcon },
  props: {
    lable: { type: String, default: undefined },
    iconName: { type: String, default: undefined },
    value: { type: String, default: undefined },
    type: { type: String, default: undefined },
    customIcon: { type: Boolean, default: false },
  },
}
</script>
