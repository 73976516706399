<template>
  <FlotoDropdownPicker
    ref="dropdownRef"
    :value="value"
    :options="options"
    :disabled="disabled"
    :size="size"
    :multiple="multiple"
    :as-input="asInput"
    :searchable="searchable"
    v-bind="$attrs"
    @change="handleChange"
    @selected="$emit('selected', $event)"
  >
    <template v-slot:trigger="slotData">
      <slot name="trigger" v-bind="slotData">
        <MTooltip v-if="suggestedItem && !value && !asInput">
          <template v-slot:trigger>
            <MTag
              v-if="suggestedItem && !value && !asInput"
              class="flex items-center"
              :variant="undefined"
              :style="{
                ...currentStyle,
              }"
              :closable="false"
            >
              <MIcon name="lightbulb-exclamation" />
              <span class="blink_me mx-2">
                {{ suggestedItem.text }}
              </span>
            </MTag>
          </template>
          {{ $tc('suggested') }} {{ $tc('value') }}: {{ suggestedItem.text }}
        </MTooltip>
      </slot>
    </template>
    <template v-if="suggestedItem && !value" v-slot:suggested-item>
      <div
        class="suggested-item-container mb-2"
        @click="handleSuggestedItemSelect"
      >
        <MTooltip placement="topLeft">
          <template v-slot:trigger>
            <div class="text-ellipsis">
              <div class="font-bold">
                {{ $tc('suggested') }} {{ $tc('value') }}
              </div>
              {{ (suggestedItem || {}).text }}
            </div>
          </template>
          {{ (suggestedItem || {}).text }}
        </MTooltip>
      </div>
    </template>
  </FlotoDropdownPicker>
</template>

<script>
import { PreferenceComputed } from '@state/modules/preference'
import { getTagStyleFromColor } from '@utils/color'
import { UserGroupsComputed } from '@state/modules/user-group'
import { getGroupsApi } from '@modules/user-groups/user-groups-api'
import { buildRelationalQualificationStructure } from '@data/qualification'

export default {
  name: 'FlotoTechnicianGroupPicker',
  model: {
    event: 'change',
  },
  props: {
    // eslint-disable-next-line
    searchable: { type: Boolean, default: true },
    suggestedValue: { type: [Number, Array, String], default: undefined },
    value: { type: [Number, String, Array], default: undefined },
    disabled: { type: Boolean, default: false },
    size: { type: String, default: undefined },
    multiple: { type: Boolean, default: false },
    // eslint-disable-next-line
    asInput: { type: Boolean, default: true },
    // eslint-disable-next-line
    mandatorySelection: { type: Boolean, default: false },
  },
  data() {
    this.unassignedValue = 0
    return {
      archivedOptions: [],
    }
  },
  computed: {
    ...UserGroupsComputed,
    ...PreferenceComputed,
    options() {
      const options = this.technicianGroupsOptions.concat(this.archivedOptions)
      if (this.mandatorySelection) {
        return options.map((o) => ({
          ...o,
          ...(o.key === 0 ? { disabled: true } : {}),
        }))
      }
      return options
    },
    suggestedItem() {
      if (this.suggestedValue && !this.value) {
        return this.options.find((o) => o.key === this.suggestedValue)
      }
      return null
    },
    currentStyle() {
      if (this.suggestedItem) {
        return getTagStyleFromColor('#89c53b', this.theme)
      }
      return {}
    },
  },
  created() {
    if (this.value) {
      const v = Array.isArray(this.value) ? this.value : [this.value]
      const options = this.options
      const allAvailableUserIds = (this.$attrs['additional-options'] || [])
        .concat(options)
        .map((u) => u.key || u.id || u.value)
      const missingItems = v.filter(
        (userId) => allAvailableUserIds.indexOf(userId) === -1
      )
      if (missingItems.length) {
        this.fetchTechnicianGroups(missingItems)
      }
    }
  },
  methods: {
    fetchTechnicianGroups(missingItems) {
      getGroupsApi(
        'technician',
        {
          quals: [
            buildRelationalQualificationStructure(
              'id',
              'in',
              missingItems,
              'long',
              'db'
            ),
          ],
        },
        undefined,
        undefined,
        { archived: true }
      ).then((data) => {
        this.archivedOptions = Object.freeze(
          (data.items || []).map((c) => ({
            key: c.id,
            text: c.name,
            disabled: c.archived || c.disabled,
            archived: c.archived,
          }))
        )
      })
    },
    handleSuggestedItemSelect() {
      this.$emit('change', this.suggestedValue)
      this.$emit('blur')
      if (this.$refs.dropdownRef) {
        this.$refs.dropdownRef.hide()
      }
    },
    handleChange($event) {
      this.$emit('change', $event || 0)
      this.$emit('blur')
    },
  },
}
</script>
<style lang="less" scoped>
.suggested-item-container {
  @apply rounded mx-2 p-2 flex flex-col cursor-pointer;

  color: var(--secondary-green);
  background: var(--success-outline-button-hover-bg);
}
</style>
