<template>
  <FlotoDropdownPicker
    :value="value"
    :search-fn="searchRequesters"
    :disabled="disabled"
    :as-input="asInput"
    :immediate-search="immediateSearch"
    :transform-options-fn="transformRequesters"
    :keep-unassigned="keepUnassigned"
    v-bind="$attrs"
    :size="dropdownSize"
    :multiple="multiple"
    @change="$emit('change', $event || 0)"
    @selected="handleSelected"
    v-on="listeners"
  >
    <template v-slot:trigger="slotData">
      <slot name="trigger" v-bind="slotData"> </slot>
    </template>
    <template v-slot:no-data>
      <h5
        class="text-center mt-4 font-normal flex justify-center items-center text-neutral-light"
      >
        <MIcon name="search" class="mr-2" />
        {{ $tc('type_to_search') }}
      </h5>
    </template>
    <template v-slot:menu-item="{ item, selectItem }">
      <div @click.stop="selectItem(item)">
        <FlotoAvatarName
          :avatar="item.avatar"
          :name="item.label"
          :size="size"
          :use-icon="[0, 'any'].indexOf(item.key) >= 0"
        />
      </div>
    </template>
  </FlotoDropdownPicker>
</template>

<script>
import { getRequestersApi } from '@modules/users/users-api'
export default {
  name: 'FlotoRequesterPicker',
  model: { event: 'change' },
  props: {
    value: { type: [Number, Array, String], default: undefined },
    disabled: { type: Boolean, default: false },
    asInput: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    immediateSearch: { type: Boolean, default: false },
    dropdownSize: { type: String, default: undefined },
    keepUnassigned: { type: Boolean, default: false },
    filters: {
      type: Object,
      default() {
        return {}
      },
    },
    size: {
      type: String,
      default: 'small',
    },
    fullObject: { type: Boolean, default: false },
  },
  data() {
    this.unassignedValue = 0
    this.selectedValueOptions = []
    this.selectedEventName = 'requesterIdSelected'
    return {
      isDirty: false,
    }
  },
  computed: {
    listeners() {
      const { change, selected, ...listeners } = this.$listeners
      return listeners
    },
  },
  methods: {
    handleSelected(data) {
      this.$emit('selected', data)
      this.$emit(this.selectedEventName, data)
    },
    transformRequesters({ items }) {
      return [
        ...(this.keepUnassigned
          ? [
              {
                text: this.$tc('unassigned'),
                key: 0,
                label: this.$tc('unassigned'),
                value: 0,
                id: 0,
                avatar: 'user',
                avatarType: 'icon',
              },
            ]
          : []),
      ].concat(
        items.map((u) => ({
          ...u,
          text: u.archived
            ? `${u.name} ${u.email ? `(${u.email})` : ''} (${this.$t(
                'archived'
              )})`
            : `${u.name} ${u.email ? `(${u.email})` : ''}`,
          key: u.id,
          label: u.archived
            ? `${u.name} ${u.email ? `(${u.email})` : ''} (${this.$t(
                'archived'
              )})`
            : `${u.name} ${u.email ? `(${u.email})` : ''}`,
          value: u.id,
          avatar: u.avatar,
          archived: u.archived,
          disabled: u.archived,
          onlyName: u.name,
        }))
      )
    },
    searchRequesters(name, selectedItems) {
      let selectedItemNames = selectedItems.map(
        (i) => i.onlyName || i.text || i.name || i.label
      )
      let excludedName = []
      if (this.$attrs['additional-options']) {
        excludedName = (this.$attrs['additional-options'] || []).map(
          (o) => o.text || o.name || o.label
        )
      }
      let excludedId = []
      if (this.$attrs['additional-options']) {
        excludedId = (this.$attrs['additional-options'] || []).map(
          (o) => o.key || o.id || o.value
        )
      }
      selectedItemNames = selectedItemNames.filter((s) => {
        return excludedName.indexOf(s) === -1
      })
      if (this.value && !this.isDirty) {
        let ids = Array.isArray(this.value) ? this.value : [this.value]
        if (excludedId.length) {
          ids = ids.filter((i) => excludedId.indexOf(i) === -1) || []
        }
        return getRequestersApi(
          { ids, archivedAndUnarchived: true },
          ids.length || 10,
          0,
          { archived: true, fullObject: this.fullObject }
        ).then((data) => {
          this.selectedValueOptions = data.items
          this.isDirty = true
          return data
        })
      }
      return getRequestersApi(
        {
          name,
          selectedName: selectedItemNames,
          archivedAndUnarchived: true,
          ...(this.filters || {}),
        },
        this.immediateSearch ? undefined : 100,
        0,
        { fullObject: this.fullObject }
      ).then((data) => {
        const ids = this.selectedValueOptions.map((i) => i.id)
        return {
          items: [
            ...data.items.filter(({ id }) => ids.indexOf(id) === -1),
            ...this.selectedValueOptions,
          ],
        }
      })
    },
  },
}
</script>

<style lang="less" module>
.searchMenuItem {
  &:hover {
    background-color: transparent;
  }

  @apply mb-2 px-2;
}
</style>
