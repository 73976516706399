var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('RequesterEmailSelector',_vm._b({ref:"requesterEmailSelectorRef",attrs:{"search-text":_vm.searchText,"options":_vm.requesters,"disabled":_vm.disabled,"auto-focus":_vm.autoFocus},on:{"change":_vm.handleChange,"select":_vm.handleSelect,"search":function($event){_vm.searchText = $event},"blur":function () {
        _vm.$emit('blur')
        _vm.handleBlur(true)
      }},scopedSlots:_vm._u([{key:"menu-item",fn:function(ref){
      var item = ref.item;
      var selectItem = ref.selectItem;
return [_c('div',{staticClass:"dropdown-item flex items-center",attrs:{"href":"javascript:;"},on:{"click":function($event){$event.stopPropagation();return selectItem(item)}}},[_c('FlotoAvatarName',{attrs:{"avatar":item.avatar,"name":_vm.autoCompleteItemText(item)}})],1)]}}])},'RequesterEmailSelector',_vm.$attrs,false)),(_vm.showHint)?_c('div',{staticClass:"text-left",staticStyle:{"line-height":"initial"}},[(_vm.selectedRequester)?[_c('div',{staticClass:"flex justify-start"},[_c('FlotoUserDrawer',{attrs:{"user":_vm.selectedRequester},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var open = ref.open;
return [_c('span',{staticClass:"text-neutral md-text-xs"},[_vm._v(" "+_vm._s(_vm.$t('requester_name'))+": ")]),_c('a',{on:{"click":open}},[_vm._v(" "+_vm._s(_vm.autoCompleteItemText(_vm.selectedRequester))+" "),(_vm.selectedRequester.removed)?_c('tempalte',[_vm._v(" ("+_vm._s(_vm.$t('archived'))+") ")]):_vm._e()],1)]}}],null,false,713417798)})],1)]:(_vm.value && _vm.showHint && !_vm.selectedRequester && _vm.isBlured)?[_c('small',{staticClass:"text-neutral"},[_vm._v(" "+_vm._s(_vm.$t('requester_will_be_created'))+" ")])]:_vm._e()],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }